<template>
  <v-container>
    <v-row class="text-center aligh-center">
      <v-card class="mx-auto" width="90%">
        <v-row class="align-center" style="height: 100px">
          <v-spacer />
          <!--v-img width="10%"  src="CCSP_Y.jpeg"/-->
          <v-spacer />
          <h1>{{ origin.name }}排行榜</h1>
          <v-spacer />
          <!--v-img width="10%"  class="mx-auto" src="MIAO.jpeg"/-->
          <v-spacer />
        </v-row>
        <!-- <v-card-text><v-text-field v-model="searchinfo" label="搜索" single-line hide-details></v-text-field></v-card-text> -->
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="searchinfo"
          :items-per-page="10"
          :options.sync="options"
          :server-items-length="totalDesserts"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [10, 100, -1] }"
          class="elevation-1"
        >
          <template v-slot:item.rank="{ item }">
            <v-chip
              :color="getColor(item.total_score)"
              :dark="getDark(item.total_score)"
            >
              {{ item.rank }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Ranklist",
  props: ["origin", "mode"],
  data: () => ({
    mode: "unknown",
    totalDesserts: 0,
    loading: true,
    options: {},
    headers: [],
    desserts: [],
  }),
  mounted: async function () {
    const commonHeader = [
      { text: "排名", align: "start", sortable: false, value: "rank" },
      { text: "姓名", value: "realname" },
    ];
    const headers = {
      CSP: [
        ...commonHeader,
        { text: "学校/单位", value: "school" },
        { text: "总分", value: "total_score" },
        { text: "第一题", value: "p1" },
        { text: "第二题", value: "p2" },
        { text: "第三题", value: "p3" },
        { text: "第四题", value: "p4" },
        { text: "第五题", value: "p5" },
      ],
      GESP: [
        ...commonHeader,
        { text: "总分", value: "total_score" },
        { text: "选择题", value: "p1" },
        { text: "判断题", value: "p2" },
        { text: "编程题1", value: "p3" },
        { text: "编程题2", value: "p4" },
      ],
    };
    this.headers = headers[this.mode];
    await this.getDataFromApi();
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    origin: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    getColor(score) {
      switch (this.mode) {
        case "CSP":
          if (score >= 400) return "yellow";
          else if (score >= 300) return "grey";
          else if (score >= 200) return "brown";
          else return "white";
        case "GESP":
          if (score >= 90) return "yellow";
          else if (score >= 75) return "grey";
          else if (score >= 60) return "brown";
          else return "white";
      }
      // var rankInfo = [
      //   [0, 0, 0],
      //   [0, 0, 0],
      //   [0, 0, 0]
      // ]
      // if (rank <= rankInfo[origin][0]) return 'yellow'
      // else if (rank <= rankInfo[origin][1]) return 'grey'
      // else if (rank <= rankInfo[origin][2]) return 'brown'
      // else return 'white'
      return "white";
    },
    getDark(score) {
      // var rankInfo = [
      //   [0, 0, 0],
      //   [0, 0, 0],
      //   [0, 0, 0]
      // ]
      // if (rank <= rankInfo[origin][2]) return true
      // else return false
      switch (this.mode) {
        case "CSP":
          if (score >= 200) return true;
          else return false;
        case "GESP":
          if (score >= 60) return true;
          else return false;
      }
      return false;
    },
    async getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const password =
        decodeURIComponent(
          (new RegExp("[?|&]" + "password" + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || ["", ""])[1].replace(/\+/g, "%20")
        ) || null;
      const q_string =
        "size=" +
        String(itemsPerPage) +
        "&page=" +
        String(page) +
        "&origin=" +
        String(this.origin.id);
      const that = this;
      this.$http
        .get(
          "/api/ranklist?" +
            q_string +
            (password ? "&password=" + password : "")
        )
        .then(function (res) {
          that.desserts = res.data.data;
          that.totalDesserts = res.data.total;
          const NProblems = { CSP: 5, GESP: 4 };
          const N = NProblems[that.mode];
          for (let i = 0; i < that.desserts.length; i++) {
            //that.desserts[i].total_score = that.desserts[i].total_score;//.toFixed(6)
            that.desserts[i].rank = i + 1 + (page - 1) * itemsPerPage;
            //that.details[i].realname = that.details[i].realname.slice(0,1)+"***";
            for (let j = 0; j < N; j++) {
              that.desserts[i]["p" + (j + 1)] =
                that.desserts[i].details[j]?.score ?? "-";
            }
          }
          that.loading = false;
        });
      setTimeout(this.getDataFromApi, 60 * 1000);
    },
  },
};
</script>
